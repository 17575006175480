import { Module } from "vuex"
import { Languages, User } from "@/types/user.type"
import { userService } from "@/services/user.service"
import { dailyService } from "@/services/daily.service"
import { earnService } from "@/services/earn.service"
import { boostService } from "@/services/boost.service"

interface UserState {
    data: User | null
    status: string
    dailyTimer: number
    turboCounter: number
    turboTime: boolean
    resetTimer: number
    clicks: number
    turboClicks: number
    stakingTimer: number
}

const state: UserState = {
    data: {
        id: "123456",
        username: "user",
        language: Languages.EN,
        balance: 0,
        turboResidue: 0,
        usedReset: false,
        resetTimer: "",
        multitapLevel: 0,
        speedLimitLevel: 0,
        autobotLevel: 0,
        miningLevel: 0,
        stakingLevel: 0,
        stakingTimer: "",
        dailyPrizeId: 0,
        isDailyPrizeShown: false,
        completedTasks: [],
        referralCode: "",
        referrals: [],
        invitedUser: "",
        invitedUserReward: 0,
        rewardTimer: "",
    },
    status: "loading",
    dailyTimer: 0,
    turboCounter: 0,
    turboTime: false,
    resetTimer: 0,
    clicks: 0,
    turboClicks: 0,
    stakingTimer: 0,
}

const mutations = {
    setUser(state: UserState, user: User) {
        state.data = user
    },
    setLoading(state: UserState, loading: boolean) {
        state.status = loading ? "loading" : state.status
    },
    setError(state: UserState, error: string) {
        state.status = "error"
    },
    setDailyTimer(state: UserState) {
        if (state.dailyTimer > 0) {
            state.dailyTimer = state.dailyTimer - 1000
        }
    },
    decrementDailyTimer(state: UserState) {
        if (state.dailyTimer > 0) {
            state.dailyTimer -= 1000
        }
    },
    decrementResetTimer(state: UserState) {
        if (state.resetTimer > 0) {
            state.resetTimer -= 1000
        } else {
            //@ts-ignore
            state.data.usedReset = false
        }
    },
    getDailyTimer(state: UserState, timer: number) {
        if (timer > 0) {
            state.dailyTimer = timer
        }
    },
    getResetTimer(state: UserState, timer: number) {
        if (timer > 0) {
            state.resetTimer = timer
        }
    },
    autobot(state: UserState) {
        if (
            state.data &&
            state.data.autobotLevel &&
            state.data.autobotLevel !== 0
        ) {
            state.data.balance += parseInt(
                (
                    (state.data.autobotLevel === 0
                        ? 0
                        : 2 ** (state.data.autobotLevel - 1)) +
                    (state.data.miningLevel === 0
                        ? 0
                        : 10 * 2 ** (state.data.miningLevel - 1)) /
                        60
                ).toFixed(1)
            )
        }
    },
    setTurboTime(state: UserState, value: boolean) {
        state.turboTime = value
    },
    setClicks(state: UserState) {
        state.clicks += 1
        localStorage.setItem("clicks", state.clicks.toString())
    },
    setTurboClicks(state: UserState) {
        state.turboClicks += 1
    },
    getClicks(state: UserState) {
        state.clicks = Number(localStorage.getItem("clicks")) || 0
    },
    clearClicks(state: UserState) {
        state.clicks = 0
        localStorage.removeItem("clicks")
    },
    clearTurboClicks(state: UserState) {
        state.turboClicks = 0
    },
    getStakingTimer(state: UserState, timer: number) {
        if (timer > 0) {
            state.stakingTimer = timer
        }
    },
    decrementStakingTimer(state: UserState) {
        if (state.stakingTimer - 1000 > 0) {
            state.stakingTimer -= 1000
        } else {
            state.stakingTimer = 0
        }
    },
}

const getters = {
    username: (state: UserState) => state.data?.username,
}

const actions = {
    async fetchUserData({ commit }: { commit: any }) {
        commit("setLoading", true)

        try {
            const userResponse: User = await userService.data()

            commit("setUser", userResponse)
        } catch (err) {
            console.error("Error fetching user data:", err)
            commit("setError", "Failed to load user data")
        }
    },
    async setDailyCollect({ commit }: { commit: any }) {
        commit("setLoading", true)

        try {
            const userResponse: User = await dailyService.collect()

            commit("setUser", userResponse)
        } catch (err) {}
    },

    async setRefRewardCollect({ commit }: { commit: any }) {
        commit("setLoading", true)

        try {
            const userResponse: User = await earnService.claim()

            commit("setUser", userResponse)
        } catch (err) {}
    },
    async fetchDailyTimer({ commit }: { commit: any }) {
        const timer: number = await dailyService.timer()

        commit("getDailyTimer", timer)
    },
    async fetchResetTimer({ commit }: { commit: any }) {
        const timer: number = await boostService.resetTimer()

        commit("getResetTimer", timer)
    },
    async fetchStakingTimer({ commit }: { commit: any }) {
        const timer: number = await boostService.stakingTimer()

        commit("getStakingTimer", timer)
    },
    setDailyTimer({ commit }: { commit: any }) {
        setInterval(() => {
            commit("decrementDailyTimer")
        }, 1000)
    },
    autobotInterval({ commit }: { commit: any }) {
        setInterval(() => {
            commit("autobot")
        }, 60000)
    },
    activateTurboTime({ commit }: { commit: any }) {
        commit("setTurboTime", true)
        setTimeout(() => {
            commit("setTurboTime", false)
        }, 30000)
    },
    activateResetTimer({ commit }: { commit: any }) {
        setInterval(() => {
            commit("decrementResetTimer")
        }, 1000)
    },
    activateStakingTimer({ commit }: { commit: any }) {
        setInterval(() => {
            commit("decrementStakingTimer")
        }, 1000)
    },
}

const userModule: Module<UserState, any> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

export default userModule
