<template>
  <div>
    <Reload />
  </div>
</template>

<script>
import Reload from "@/components/Reload.vue";
export default {
  components: {
    Reload,
  },
};
</script>
