<template>
    <div class="page_menu d-grid align-items-end position-fixed">
        <div class="menu_item active">
            <div class="menu_item_in">
                <router-link to="/">
                    <svg
                        width="52"
                        height="39"
                        viewBox="0 0 52 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="26.2124"
                            cy="19.4414"
                            r="11.3154"
                            fill="white"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
        <div class="menu_item">
            <div class="menu_item_in">
                <router-link to="/refferal">
                    <svg
                        width="53"
                        height="40"
                        viewBox="0 0 53 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="34.1543"
                            cy="12.5047"
                            r="3.51168"
                            fill="#41FDB9"
                        />
                        <circle
                            cx="34.1543"
                            cy="12.5047"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <circle
                            cx="34.1543"
                            cy="12.5047"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <circle
                            cx="19.3272"
                            cy="19.5281"
                            r="3.51168"
                            fill="#41FDB9"
                        />
                        <circle
                            cx="19.3272"
                            cy="19.5281"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <circle
                            cx="19.3272"
                            cy="19.5281"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <circle
                            cx="34.1543"
                            cy="27.3318"
                            r="3.51168"
                            fill="#41FDB9"
                        />
                        <circle
                            cx="34.1543"
                            cy="27.3318"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <circle
                            cx="34.1543"
                            cy="27.3318"
                            r="3.51168"
                            stroke="#41FDB9"
                            stroke-width="0.780374"
                        />
                        <path
                            d="M31.4249 27.3583C32.0042 27.6452 32.7064 27.4081 32.9933 26.8288C33.2802 26.2494 33.0431 25.5472 32.4638 25.2603L31.4249 27.3583ZM32.4638 25.2603L20.8879 19.5279L19.849 21.6259L31.4249 27.3583L32.4638 25.2603Z"
                            fill="#41FDB9"
                        />
                        <path
                            d="M31.4249 27.3583C32.0042 27.6452 32.7064 27.4081 32.9933 26.8288C33.2802 26.2494 33.0431 25.5472 32.4638 25.2603L31.4249 27.3583ZM32.4638 25.2603L20.8879 19.5279L19.849 21.6259L31.4249 27.3583L32.4638 25.2603Z"
                            fill="#41FDB9"
                        />
                        <path
                            d="M20.1765 18.0465C19.5961 18.3312 19.3563 19.0325 19.641 19.613C19.9257 20.1934 20.627 20.4331 21.2074 20.1485L20.1765 18.0465ZM31.7741 12.3582L20.1765 18.0465L21.2074 20.1485L32.805 14.4601L31.7741 12.3582Z"
                            fill="#41FDB9"
                        />
                        <path
                            d="M20.1765 18.0465C19.5961 18.3312 19.3563 19.0325 19.641 19.613C19.9257 20.1934 20.627 20.4331 21.2074 20.1485L20.1765 18.0465ZM31.7741 12.3582L20.1765 18.0465L21.2074 20.1485L32.805 14.4601L31.7741 12.3582Z"
                            fill="#41FDB9"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
        <div class="menu_item">
            <div class="menu_item_in">
                <router-link to="/earn">
                    <svg
                        width="52"
                        height="40"
                        viewBox="0 0 52 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.1372 17.4167L16.1973 20.6294L15.0173 19.4687C14.5172 18.9766 13.7071 18.9766 13.2067 19.4687C12.7063 19.9609 12.7066 20.758 13.2067 21.2505L15.3406 23.3503C15.5806 23.5902 15.9076 23.7214 16.1978 23.7214C16.2095 23.7214 16.2195 23.7214 16.2312 23.7206C16.5829 23.7116 16.9145 23.5615 17.1498 23.3048L20.9907 19.1051C21.4641 18.5875 21.4207 17.7911 20.8957 17.3249C20.4174 16.8602 19.6119 16.9022 19.1372 17.4167ZM19.1372 9.01942L16.1973 12.2301L15.0173 11.0694C14.5172 10.5772 13.7071 10.5772 13.2067 11.0694C12.7063 11.5615 12.7066 12.3587 13.2067 12.8511L15.3406 14.9509C15.5806 15.1908 15.9076 15.3221 16.1978 15.3221C16.2095 15.3221 16.2195 15.3221 16.2312 15.3213C16.5829 15.3122 16.9145 15.1622 17.1498 14.9054L20.9907 10.7057C21.4641 10.1882 21.4207 9.39174 20.8957 8.92558C20.4174 8.46138 19.6119 8.50233 19.1372 9.01942ZM15.3923 26.194C13.9781 26.194 12.8317 27.3221 12.8317 28.7138C12.8317 30.1055 13.9781 31.2336 15.3923 31.2336C16.8065 31.2336 17.9529 30.1055 17.9529 28.7138C17.9529 27.3699 16.8065 26.194 15.3923 26.194ZM26.4882 13.6422H38.4376C39.3802 13.6422 40.1446 12.8899 40.1446 11.9623C40.1446 11.0347 39.3802 10.2825 38.4376 10.2825H26.4882C25.544 10.2825 24.7811 11.0347 24.7811 11.9623C24.7811 12.8899 25.544 13.6422 26.4882 13.6422ZM38.4376 18.6818H26.4882C25.544 18.6818 24.7811 19.4325 24.7811 20.3617C24.7811 21.2909 25.5456 22.0416 26.4882 22.0416H38.4376C39.3802 22.0416 40.1446 21.2893 40.1446 20.3617C40.1446 19.4341 39.3818 18.6818 38.4376 18.6818ZM38.4376 27.0812H23.0741C22.1314 27.0812 21.367 27.8335 21.367 28.7611C21.367 29.6887 22.1314 30.4409 23.0741 30.4409H38.4376C39.3802 30.4409 40.1446 29.6887 40.1446 28.7611C40.1446 27.8335 39.3818 27.0812 38.4376 27.0812Z"
                            fill="#41FDB9"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
        <div class="menu_item">
            <div class="menu_item_in">
                <router-link to="/boost">
                    <svg
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_2220_908)">
                            <path
                                d="M15.308 21.7705H12.0889C11.8755 21.7705 11.6708 21.8553 11.5199 22.0062C11.369 22.1571 11.2842 22.3618 11.2842 22.5753C11.2842 22.7887 11.369 22.9934 11.5199 23.1443C11.6708 23.2952 11.8755 23.38 12.0889 23.38H15.308C15.5214 23.38 15.7261 23.2952 15.877 23.1443C16.028 22.9934 16.1127 22.7887 16.1127 22.5753C16.1127 22.3618 16.028 22.1571 15.877 22.0062C15.7261 21.8553 15.5214 21.7705 15.308 21.7705Z"
                                fill="#41FDB9"
                            />
                            <path
                                d="M22.9132 14.5176L19.8753 10.866C19.7763 9.66328 19.5053 8.48098 19.0705 7.35524C17.9036 4.31727 15.8414 2.32549 14.7047 1.41007C14.4212 1.17834 14.0667 1.05092 13.7005 1.04909C13.3343 1.04726 12.9786 1.17113 12.6928 1.40001C11.546 2.31543 9.45362 4.30721 8.27666 7.34518C7.82524 8.49424 7.55052 9.70506 7.46184 10.9364L4.48423 14.5176C4.32628 14.7063 4.21339 14.9284 4.15413 15.1672C4.09487 15.406 4.09079 15.6552 4.1422 15.8958L5.38958 21.4888C5.44827 21.7556 5.5743 22.003 5.75568 22.2072C5.93706 22.4115 6.16773 22.5659 6.42571 22.6557C6.6007 22.7151 6.78415 22.7456 6.96893 22.7463C7.33104 22.7443 7.68191 22.6203 7.96482 22.3942L10.7614 20.161H16.6361L19.4327 22.3942C19.7156 22.6203 20.0664 22.7443 20.4285 22.7463C20.6133 22.7456 20.7968 22.7151 20.9718 22.6557C21.2297 22.5659 21.4604 22.4115 21.6418 22.2072C21.8232 22.003 21.9492 21.7556 22.0079 21.4888L23.2553 15.8958C23.3067 15.6552 23.3026 15.406 23.2433 15.1672C23.1841 14.9284 23.0712 14.7063 22.9132 14.5176ZM6.95887 21.1368L5.72155 15.5437L7.5222 13.3809C7.75692 15.2654 8.39738 17.1901 9.44356 19.155L6.95887 21.1368ZM13.6987 10.9062C13.46 10.9062 13.2266 10.8354 13.0281 10.7028C12.8296 10.5702 12.6748 10.3816 12.5835 10.1611C12.4921 9.94048 12.4682 9.69776 12.5148 9.4636C12.5614 9.22944 12.6763 9.01435 12.8452 8.84553C13.014 8.6767 13.2291 8.56174 13.4632 8.51516C13.6974 8.46858 13.9401 8.49249 14.1607 8.58385C14.3813 8.67522 14.5698 8.82994 14.7024 9.02845C14.8351 9.22697 14.9059 9.46035 14.9059 9.6991C14.9059 10.0193 14.7787 10.3263 14.5523 10.5527C14.3259 10.7791 14.0189 10.9062 13.6987 10.9062ZM20.4386 21.1368L17.9438 19.145C18.99 17.1633 19.6137 15.2218 19.835 13.3306L21.6759 15.5437L20.4386 21.1368Z"
                                fill="#41FDB9"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_2220_908">
                                <rect
                                    width="25.7523"
                                    height="25.7523"
                                    fill="white"
                                    transform="translate(0.822266 0.0419922)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </router-link>
            </div>
        </div>
        <div class="menu_item">
            <div class="menu_item_in">
                <router-link to="/wallet">
                    <svg
                        width="52"
                        height="40"
                        viewBox="0 0 52 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.2777 11.6285H34.9257C35.1318 11.6284 35.3378 11.6412 35.5423 11.6669C35.4729 11.1901 35.3058 10.7319 35.051 10.3201C34.7961 9.90828 34.4588 9.55131 34.0593 9.2707C33.6599 8.9901 33.2066 8.79167 32.7268 8.68739C32.247 8.58311 31.7506 8.57514 31.2676 8.66395L16.7537 11.0924H16.7372C15.8261 11.2631 15.016 11.7683 14.4722 12.5047C15.2915 11.9335 16.2722 11.6273 17.2777 11.6285Z"
                            fill="#41FDB9"
                        />
                        <path
                            d="M35.0959 13.2852H17.2588C16.313 13.2862 15.4062 13.6482 14.7374 14.2919C14.0686 14.9357 13.6924 15.8084 13.6914 16.7188V27.0197C13.6924 27.9301 14.0686 28.8029 14.7374 29.4466C15.4062 30.0903 16.313 30.4524 17.2588 30.4534H35.0959C36.0418 30.4524 36.9486 30.0903 37.6174 29.4466C38.2862 28.8029 38.6623 27.9301 38.6634 27.0197V16.7188C38.6623 15.8084 38.2862 14.9357 37.6174 14.2919C36.9486 13.6482 36.0418 13.2862 35.0959 13.2852ZM32.4482 23.5861C32.0955 23.5861 31.7506 23.4854 31.4573 23.2968C31.1639 23.1081 30.9353 22.84 30.8003 22.5263C30.6653 22.2126 30.63 21.8674 30.6988 21.5343C30.7676 21.2013 30.9375 20.8954 31.187 20.6553C31.4364 20.4152 31.7543 20.2517 32.1003 20.1854C32.4463 20.1192 32.8049 20.1532 33.1308 20.2831C33.4568 20.4131 33.7354 20.6331 33.9314 20.9155C34.1273 21.1978 34.232 21.5297 34.232 21.8693C34.232 22.3246 34.044 22.7613 33.7095 23.0832C33.375 23.4052 32.9213 23.5861 32.4482 23.5861Z"
                            fill="#41FDB9"
                        />
                        <path
                            d="M13.6914 20.3083V14.8911C13.6914 13.7113 14.3788 11.7333 16.7648 11.3048C18.7899 10.9438 20.795 10.9438 20.795 10.9438C20.795 10.9438 22.1126 11.815 21.0241 11.815C19.9357 11.815 19.9643 13.1489 21.0241 13.1489C22.0839 13.1489 21.0241 14.4283 21.0241 14.4283L16.7563 19.0289L13.6914 20.3083Z"
                            fill="#41FDB9"
                        />
                    </svg>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            paths: ["/", "/refferal", "/earn", "/boost", "/wallet"],
        }
    },
    watch: {
        $route(to) {
            const index = this.paths.indexOf(to.path) + 1
            this.menubar(index)
        },
    },
    methods: {
        menubar(id) {
            document.querySelector(
                ".page_menu"
            ).style.backgroundImage = `url(/img/Subtract${id}.svg)`
            document.querySelectorAll(".menu_item").forEach((e, i) => {
                if (id == i + 1) {
                    e.classList.add("active")
                } else {
                    e.classList.remove("active")
                }
            })
        },
    },
}
</script>
<style lang="scss">
.page_menu {
    max-width: 334px;
    width: 100%;
    min-height: 89px;
    height: 89px;
    grid-template-columns: repeat(5, 1fr);
    background: url("@/assets/img/Subtract1.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 16px;
    z-index: 1000;
    transition: 0.3s;
    .menu_item {
        display: flex;
        justify-content: center;
        align-items: center;
        .menu_item_in {
            width: 55px;
            height: 55px;
            display: flex;
            align-items: end;
            justify-content: center;
            border-radius: 50%;
            transition: 0.3s;
        }
        &:nth-child(1) {
            .menu_item_in {
                padding-bottom: 11.7px;
            }
        }
        &:nth-child(2) {
            .menu_item_in {
                padding-bottom: 11.7px;
                margin-left: -1.8px;
            }
        }
        &:nth-child(3) {
            .menu_item_in {
                padding-bottom: 11.7px;
                margin-left: -10px;
            }
        }
        &:nth-child(4) {
            .menu_item_in {
                padding-bottom: 17.9px;
                margin-left: -2.3px;
            }
        }
        &:nth-child(5) {
            .menu_item_in {
                padding-bottom: 11.7px;
                margin-left: -1.3px;
            }
        }
        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: end;
            svg {
                line-height: 0;
            }
        }
    }
    .menu_item.active {
        .menu_item_in {
            align-items: center;
            background: #202e49;
            padding: 0;
            transform: translateY(-36px);
        }
    }
}
</style>
