import $api from "@/api/interceptors"

class ClickService {
    async default(amount: number) {
        return (
            await $api.post("/click", {
                amount,
            })
        ).data.data
    }

    async turbo(amount: number) {
        return (
            await $api.post("/click/turbo", {
                amount,
            })
        ).data.data
    }
}

export const clickService = new ClickService()
