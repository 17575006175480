<template>
  <div>
    <Wallet />
  </div>
</template>

<script>
import Wallet from "@/components/Wallet.vue";
export default {
  components: {
    Wallet,
  },
};
</script>
