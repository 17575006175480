import axios from "axios"

const $api = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
})

$api.interceptors.request.use((config) => {
    //@ts-ignore
    config.headers.Authorization = window.Telegram.WebApp.initData
    return config
})

export default $api
