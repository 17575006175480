<template>
    <div
        class="modal_container"
        :class="modal ? 'active' : ''"
        :style="{ top: modal ? '40px' : '-54px' }"
    >
        <button class="modal_btn position-relative border-0">
            <div class="left_img position-absolute">
                <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.2302 13.5937L15.3131 2.32489C14.4052 0.846246 13.0163 0 11.5 0C9.98366 0 8.59485 0.846246 7.68691 2.3226L0.769766 13.5937C-0.149868 15.0906 -0.25049 16.6871 0.494812 17.9736C1.23894 19.2612 2.69211 20 4.47989 20H18.5201C20.3079 20 21.7611 19.2612 22.5052 17.9747C23.2505 16.6882 23.1499 15.0918 22.2302 13.5937ZM11.5 16.0535C10.5008 16.0535 9.68647 15.2587 9.68647 14.2821C9.68647 13.3044 10.4996 12.5084 11.5 12.5084C12.5004 12.5084 13.3135 13.3044 13.3135 14.2821C13.3135 15.2587 12.4992 16.0535 11.5 16.0535ZM13.4106 7.56361C13.3978 7.59906 11.7714 11.5295 11.7714 11.5295C11.727 11.637 11.6193 11.7079 11.5012 11.7079C11.383 11.7079 11.2754 11.637 11.2309 11.5295L9.6034 7.59792C9.4981 7.33147 9.45247 7.09131 9.45247 6.84888C9.45247 5.74533 10.3709 4.84762 11.5 4.84762C12.6291 4.84762 13.5475 5.74533 13.5475 6.84888C13.5475 7.09131 13.5019 7.33147 13.4106 7.56361Z"
                        fill="#FF8D8D"
                    />
                    <path
                        d="M9.13834 7.78171L9.1384 7.78169C9.012 7.46186 8.95247 7.15953 8.95247 6.84888C8.95247 5.45847 10.1056 4.34762 11.5 4.34762C12.8944 4.34762 14.0475 5.45847 14.0475 6.84888C14.0475 7.155 13.9897 7.45713 13.876 7.74654L13.8758 7.74647C13.8268 7.86831 13.4306 8.82641 13.0483 9.75095L12.4878 11.106L12.3034 11.5517L12.2517 11.6765L12.2381 11.7094L12.2346 11.7179L12.2338 11.72L12.2335 11.7205L12.2335 11.7206L9.13834 7.78171ZM9.13834 7.78171L9.14142 7.78916M9.13834 7.78171L9.14142 7.78916M9.14142 7.78916L10.7689 11.7206C10.8257 11.8581 10.9183 11.9721 11.0325 12.0552C9.98607 12.2675 9.18647 13.1776 9.18647 14.2821C9.18647 15.546 10.2359 16.5535 11.5 16.5535M9.14142 7.78916L11.5 16.5535M11.5 16.5535C12.7641 16.5535 13.8135 15.546 13.8135 14.2821C13.8135 13.1782 13.0149 12.2685 11.9694 12.0556M11.5 16.5535L11.9694 12.0556M11.9694 12.0556C12.0837 11.9724 12.1765 11.8583 12.2335 11.7207L11.9694 12.0556ZM21.8041 13.8553L21.8041 13.8553C22.6488 15.2312 22.7071 16.6287 22.0725 17.7241L22.0724 17.7244C21.4371 18.8227 20.172 19.5 18.5201 19.5H4.47989C2.82802 19.5 1.56305 18.8228 0.927723 17.7234L0.927456 17.7229C0.29286 16.6275 0.351266 15.2301 1.19579 13.8554L1.19591 13.8552L8.11282 2.58453C8.11285 2.58447 8.11289 2.58441 8.11292 2.58436C8.94534 1.2309 10.185 0.5 11.5 0.5C12.8149 0.5 14.0545 1.23082 14.887 2.58646C14.887 2.58648 14.887 2.5865 14.887 2.58652L21.8041 13.8553Z"
                        stroke="#FF8181"
                        stroke-opacity="0.2"
                    />
                </svg>
            </div>
            <span class="btn_content">Failed</span>
            <div class="right_img position-absolute">
                <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.2302 13.5937L15.3131 2.32489C14.4052 0.846246 13.0163 0 11.5 0C9.98366 0 8.59485 0.846246 7.68691 2.3226L0.769766 13.5937C-0.149868 15.0906 -0.25049 16.6871 0.494812 17.9736C1.23894 19.2612 2.69211 20 4.47989 20H18.5201C20.3079 20 21.7611 19.2612 22.5052 17.9747C23.2505 16.6882 23.1499 15.0918 22.2302 13.5937ZM11.5 16.0535C10.5008 16.0535 9.68647 15.2587 9.68647 14.2821C9.68647 13.3044 10.4996 12.5084 11.5 12.5084C12.5004 12.5084 13.3135 13.3044 13.3135 14.2821C13.3135 15.2587 12.4992 16.0535 11.5 16.0535ZM13.4106 7.56361C13.3978 7.59906 11.7714 11.5295 11.7714 11.5295C11.727 11.637 11.6193 11.7079 11.5012 11.7079C11.383 11.7079 11.2754 11.637 11.2309 11.5295L9.6034 7.59792C9.4981 7.33147 9.45247 7.09131 9.45247 6.84888C9.45247 5.74533 10.3709 4.84762 11.5 4.84762C12.6291 4.84762 13.5475 5.74533 13.5475 6.84888C13.5475 7.09131 13.5019 7.33147 13.4106 7.56361Z"
                        fill="#FF8D8D"
                    />
                    <path
                        d="M9.13834 7.78171L9.1384 7.78169C9.012 7.46186 8.95247 7.15953 8.95247 6.84888C8.95247 5.45847 10.1056 4.34762 11.5 4.34762C12.8944 4.34762 14.0475 5.45847 14.0475 6.84888C14.0475 7.155 13.9897 7.45713 13.876 7.74654L13.8758 7.74647C13.8268 7.86831 13.4306 8.82641 13.0483 9.75095L12.4878 11.106L12.3034 11.5517L12.2517 11.6765L12.2381 11.7094L12.2346 11.7179L12.2338 11.72L12.2335 11.7205L12.2335 11.7206L9.13834 7.78171ZM9.13834 7.78171L9.14142 7.78916M9.13834 7.78171L9.14142 7.78916M9.14142 7.78916L10.7689 11.7206C10.8257 11.8581 10.9183 11.9721 11.0325 12.0552C9.98607 12.2675 9.18647 13.1776 9.18647 14.2821C9.18647 15.546 10.2359 16.5535 11.5 16.5535M9.14142 7.78916L11.5 16.5535M11.5 16.5535C12.7641 16.5535 13.8135 15.546 13.8135 14.2821C13.8135 13.1782 13.0149 12.2685 11.9694 12.0556M11.5 16.5535L11.9694 12.0556M11.9694 12.0556C12.0837 11.9724 12.1765 11.8583 12.2335 11.7207L11.9694 12.0556ZM21.8041 13.8553L21.8041 13.8553C22.6488 15.2312 22.7071 16.6287 22.0725 17.7241L22.0724 17.7244C21.4371 18.8227 20.172 19.5 18.5201 19.5H4.47989C2.82802 19.5 1.56305 18.8228 0.927723 17.7234L0.927456 17.7229C0.29286 16.6275 0.351266 15.2301 1.19579 13.8554L1.19591 13.8552L8.11282 2.58453C8.11285 2.58447 8.11289 2.58441 8.11292 2.58436C8.94534 1.2309 10.185 0.5 11.5 0.5C12.8149 0.5 14.0545 1.23082 14.887 2.58646C14.887 2.58648 14.887 2.5865 14.887 2.58652L21.8041 13.8553Z"
                        stroke="#FF8181"
                        stroke-opacity="0.2"
                    />
                </svg>
            </div>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        modal: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
.modal_container {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    transition: opacity 0.2s, top 0.4s;
    .modal_btn {
        width: 305px;
        height: 54px;
        background: url("@/assets/img/failed.svg");
        border-radius: 15px;
        font-family: var(--second-family);
        font-weight: 600;
        font-size: 21px;
        text-align: center;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;

        .left_img {
            left: 89px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 0;
        }
        .right_img {
            right: 89px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 0;
        }
    }
}
.modal_container.active {
    opacity: 1;

    .modal_btn {
        visibility: visible;
        opacity: 1;
        animation: btn 3s;
        .btn_content {
            display: block;
            position: relative;
            animation: scaleanim 3s forwards;
            transform: scale(1);
        }
        .left_img,
        .right_img {
            display: block;
            animation: imganim 3s forwards;
            transform: translateY(-50%) scale(0.5);
        }
        .left_img {
            animation: rotateanim 3s forwards;
        }
        .right_img {
            animation: rotateanim2 3s forwards;
        }
        @keyframes rotateanim {
            0% {
                transform: translateY(-50%) scale(0.5) rotate(0deg);
            }
            40% {
                transform: translateY(-50%) scale(1.3) rotate(-15deg);
            }
            60% {
                transform: translateY(-50%) scale(1) rotate(15deg);
            }
            100% {
                transform: translateY(-50%) scale(1) rotate(0deg);
            }
        }
        @keyframes rotateanim2 {
            0% {
                transform: translateY(-50%) scale(0.5) rotate(0deg);
            }
            40% {
                transform: translateY(-50%) scale(1.3) rotate(15deg);
            }
            60% {
                transform: translateY(-50%) scale(1) rotate(-15deg);
            }
            100% {
                transform: translateY(-50%) scale(1) rotate(0deg);
            }
        }
        @keyframes scaleanim {
            0% {
                transform: scale(0.5);
            }
            40% {
                transform: scale(1);
            }
            60% {
                transform: scale(1);
            }
            100% {
                transform: scale(1);
            }
        }
    }
    @keyframes btn {
        0%,
        25% {
            background: url("@/assets/img/failed1.svg");
        }
        50%,
        75%,
        100% {
            background: url("@/assets/img/failed.svg");
        }
    }
}
</style>
