import $api from "@/api/interceptors"
import { Boosts } from "@/types/user.type"

class BoostService {
    async turbo() {
        return (await $api.get("/boost/turbo")).data.data
    }

    async reset() {
        return (await $api.get("/boost/reset")).data.data
    }

    async resetTimer() {
        return (await $api.get("/boost/reset/timer")).data.data
    }

    async upgrade(upgrade: Boosts) {
        return (
            await $api.post("/boost/upgrade", {
                upgrade,
            })
        ).data.data
    }

    async stakingTimer() {
        return (await $api.get("/boost/staking/timer")).data.data
    }
}

export const boostService = new BoostService()
