export enum Languages {
    EN = "en",
    RU = "ru",
}

export enum Boosts {
    MULTITAP = "multitap",
    SPEED_LIMIT = "speedLimit",
    AUTOBOT = "autobot",
    MINING = "mining",
    STAKING = "staking",
}

export interface User {
    id: string
    username: string
    language: Languages
    balance: number
    turboResidue: number
    usedReset: boolean
    resetTimer: string
    multitapLevel: number
    speedLimitLevel: number
    autobotLevel: number
    miningLevel: number
    stakingLevel: number
    stakingTimer: string
    dailyPrizeId: number
    isDailyPrizeShown: boolean
    completedTasks: string[]
    referralCode: string
    referrals: string[]
    invitedUser: string
    invitedUserReward: number
    rewardTimer: string
}
