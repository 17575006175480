import $api from "@/api/interceptors"

class DailyService {
    async collect() {
        return (await $api.get("/daily/collect")).data.data
    }

    async timer() {
        return (await $api.get("/daily/timer")).data.data
    }
}

export const dailyService = new DailyService()
