<template>
    <div>
        <PageLoading v-if="loading" />
        <div v-else>
            <div key="desktop" class="app" v-if="isPC">
                <PC />
            </div>
            <div key="mobile" v-else class="app">
                <router-view />
                <Menu />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex"
import Menu from "@/components/Menu.vue"
import PageLoading from "@/components/PageLoading.vue"
import PC from "@/views/qr-code.vue"
import axios from "axios"
import { userService } from "./services/user.service"

export default {
    components: {
        Menu,
        PageLoading,
        PC,
    },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        ...mapState("user", ["data"]),
        isPC() {
            return !(
                Telegram.WebApp.platform === "ios" ||
                Telegram.WebApp.platform === "android"
            )
        },
    },
    methods: {
        ...mapActions("user", [
            "fetchUserData",
            "fetchDailyTimer",
            "fetchResetTimer",
            "setDailyTimer",
            "autobotInterval",
            "getClicks",
            "activateResetTimer",
            "fetchStakingTimer",
            "activateStakingTimer",
        ]),
        ...mapMutations("user", ["getClicks"]),
    },
    watch: {
        "data.usedReset"(newVal) {
            if (newVal) {
                this.fetchResetTimer()
                this.activateResetTimer()
            }
        },
        "data.stakingTimer"(newVal) {
            if (newVal) {
                this.fetchStakingTimer()
                this.activateStakingTimer()
            }
        },
        "Telegram.WebApp.isExpanded"(newVal) {
            if (!newVal) {
                Telegram.WebApp.expand()
            }
        },
    },
    async mounted() {
        try {
            Telegram.WebApp.setHeaderColor("#41FDB9")
            const isFirst = !(await userService.isFirst(
                Telegram.WebApp.initDataUnsafe.user.id
            ))

            if (isFirst) {
                this.$router.push("/slider")
            }
            if (Telegram.WebApp.initDataUnsafe.start_param) {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/earn/refer`,
                    {
                        userId: Telegram.WebApp.initDataUnsafe.user.id.toString(),
                        username:
                            Telegram.WebApp.initDataUnsafe.user.username ||
                            Telegram.WebApp.initDataUnsafe.user.first_name,
                        code: Telegram.WebApp.initDataUnsafe.start_param,
                    }
                )
            }

            if (
                Telegram.WebApp.platform === "ios" ||
                Telegram.WebApp.platform === "android"
            ) {
                await this.fetchUserData()
                await this.fetchDailyTimer()
                this.setDailyTimer()
                this.autobotInterval()
                this.getClicks()

                setTimeout(() => {
                    document
                        ?.querySelector(".page_loading")
                        ?.classList.add("no_loading")
                }, 2000)
            }

            this.loading = false
        } catch (error) {
            console.error(error)
            this.loading = false
        }
    },
}
</script>
