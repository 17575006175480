import $api from "@/api/interceptors"

class EarnService {
    async task(title: string, reward: number) {
        return (
            await $api.post("/earn/task", {
                title,
                reward,
            })
        ).data.data
    }

    async claim() {
        return (await $api.get("/earn/refer/claim")).data.data
    }
}

export const earnService = new EarnService()
