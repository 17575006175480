<template>
    <div class="boost">
        <div class="boost_in">
            <div class="main_container position-relative">
                <div class="back position-absolute">
                    <router-link to="#" @click="$router.go(-1)">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5707 25.4734L5.91016 18.5008C5.51172 18.1156 5.3125 17.591 5.3125 17.0133C5.3125 16.4356 5.51172 15.9176 5.91016 15.5258L12.5707 8.52657C13.3609 7.6965 14.6492 7.6965 15.4395 8.52657C16.2297 9.35665 16.2297 10.6981 15.4395 11.5281L12.2387 14.875L26.6488 14.875C27.7777 14.875 28.6875 15.8246 28.6875 17C28.6875 18.1754 27.7777 19.125 26.6555 19.125L12.2453 19.125L15.4461 22.4719C16.2363 23.302 16.2363 24.6434 15.4461 25.4734C14.6492 26.3035 13.3676 26.3035 12.5707 25.4734Z"
                                fill="#DBDBDB"
                            />
                        </svg>
                    </router-link>
                </div>
                <NomoneyModal :modal="showNoMoney" />
                <SuccessfulModal :modal="showSuccess" />
                <FailedModal :modal="showFailed" />
                <TurboactiveModal :modal="showTurbo" />
                <div class="logo d-flex justify-content-center">
                    <router-link to="/">
                        <img
                            height="52px"
                            src="@/assets/img/Logo.svg"
                            alt="logo"
                        />
                    </router-link>
                </div>
                <div
                    class="balance d-flex justify-content-between align-items-center"
                >
                    <div class="head">Balance</div>
                    <div class="balance_val">
                        {{
                            (
                                data.balance +
                                (turboTime
                                    ? turboClicks * (data.multitapLevel + 11)
                                    : clicks * (data.multitapLevel + 1))
                            ).toLocaleString("ru-RU")
                        }}
                    </div>
                </div>
                <div class="daily_boosters">
                    <div class="title">Daily boosters</div>
                    <div class="daily_boosters_card d-grid">
                        <div class="card_item" @click="turbo()">
                            <div class="daily_boosters_swiper">
                                <div
                                    class="daily_boosters_swiper_block d-flex justify-content-between"
                                    :style="{
                                        background:
                                            turboList[data.turboResidue]
                                                .background,
                                    }"
                                >
                                    <div class="card_title__block">
                                        <div class="card_title">Turbo</div>
                                        <div>{{ data.turboResidue }}/3</div>
                                    </div>

                                    <div
                                        class="card_img d-flex align-items-center justify-content-center"
                                    >
                                        <img
                                            src="@/assets/img/rocket.svg"
                                            alt="rocket"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card_item rest_boots" @click="reset()">
                            <div class="daily_boosters_swiper">
                                <div
                                    class="rest_boots_slide daily_boosters_swiper_block d-flex justify-content-between"
                                    :style="{
                                        background:
                                            resetList[data.usedReset ? 1 : 0]
                                                .background,
                                    }"
                                >
                                    <div>
                                        <div class="card_title">Reset</div>
                                        <div class="card_hour">
                                            <span v-if="!data.usedReset"
                                                >Once 24h</span
                                            >
                                            <span v-else>{{
                                                resetFormattedTimer
                                            }}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="card_img d-flex align-items-center justify-content-center"
                                    >
                                        <img
                                            height=""
                                            src="@/assets/img/battery.svg"
                                            alt="battery"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="level_up">
                    <div class="title">Level - ups</div>
                    <div class="level_up_list d-flex flex-column">
                        <div
                            class="list_item d-flex align-items-center justify-content-between"
                            @click="loadir(1)"
                        >
                            <div class="list_item_in d-flex align-items-center">
                                <div
                                    class="list_img d-flex align-items-center justify-content-center"
                                >
                                    <img :src="imgUrl1" alt="left-click" />
                                </div>
                                <div class="list_body h-100">
                                    <div class="list_body_title">
                                        Multitap
                                        <span
                                            >{{
                                                data.multitapLevel + 1
                                            }}
                                            lvl</span
                                        >
                                    </div>
                                    <div class="list_body_sum">
                                        {{
                                            Math.round(
                                                3000 * 1.4 ** data.multitapLevel
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <Loading />
                        </div>
                        <div
                            class="list_item d-flex align-items-center justify-content-between"
                            @click="loadir(2)"
                        >
                            <div class="list_item_in d-flex align-items-center">
                                <div
                                    class="list_img d-flex align-items-center justify-content-center"
                                >
                                    <img :src="imgUrl2" alt="left-click" />
                                </div>
                                <div class="list_body h-100">
                                    <div class="list_body_title">
                                        Speed limit
                                        <span
                                            >{{
                                                data.speedLimitLevel + 1
                                            }}
                                            lvl</span
                                        >
                                    </div>
                                    <div class="list_body_sum">
                                        {{
                                            Math.round(
                                                50000 *
                                                    2.1 ** data.speedLimitLevel
                                            )
                                        }}
                                    </div>
                                    <div class="list_body_time">
                                        {{ data.speedLimitLevel + 15 }} click /
                                        sec
                                    </div>
                                </div>
                            </div>
                            <Loading />
                        </div>
                        <div
                            class="list_item d-flex align-items-center justify-content-between"
                            @click="loadir(3)"
                        >
                            <div class="list_item_in d-flex align-items-center">
                                <div
                                    class="list_img d-flex align-items-center justify-content-center"
                                >
                                    <img :src="imgUrl3" alt="left-click" />
                                </div>
                                <div class="list_body h-100">
                                    <div class="list_body_title">
                                        Autobot
                                        <span
                                            >{{
                                                data.autobotLevel + 1
                                            }}
                                            lvl</span
                                        >
                                    </div>
                                    <div class="list_body_sum">
                                        {{
                                            Math.round(
                                                10000 * 2.1 ** data.autobotLevel
                                            )
                                        }}
                                    </div>
                                    <div class="list_body_time">
                                        {{ 2 ** data.autobotLevel }}/min
                                    </div>
                                </div>
                            </div>
                            <Loading />
                        </div>
                        <div
                            class="list_item d-flex align-items-center justify-content-between"
                            @click="loadir(4)"
                        >
                            <div class="list_item_in d-flex align-items-center">
                                <div
                                    class="list_img d-flex align-items-center justify-content-center"
                                >
                                    <img :src="imgUrl4" alt="left-click" />
                                </div>
                                <div class="list_body h-100">
                                    <div class="list_body_title">
                                        Mining
                                        <span
                                            >{{
                                                data.miningLevel + 1
                                            }}
                                            lvl</span
                                        >
                                    </div>
                                    <div class="list_body_sum">
                                        {{
                                            Math.round(
                                                50000 * 2.8 ** data.miningLevel
                                            )
                                        }}
                                    </div>
                                    <div class="list_body_time">
                                        {{ 10 * 2 ** data.miningLevel }}/1h
                                    </div>
                                </div>
                            </div>
                            <Loading />
                        </div>
                        <div
                            class="list_item d-flex align-items-center justify-content-between"
                            @click="loadir(5)"
                        >
                            <div class="list_item_in d-flex align-items-center">
                                <div
                                    class="list_img d-flex align-items-center justify-content-center"
                                >
                                    <img :src="imgUrl5" alt="left-click" />
                                </div>
                                <div class="list_body h-100">
                                    <div class="list_body_title">
                                        Staking
                                        <span>1 lvl</span>
                                    </div>
                                    <div
                                        v-if="stakingTimer === 0"
                                        class="list_body_sum"
                                    >
                                        500 000
                                    </div>
                                    <div
                                        v-if="stakingTimer === 0"
                                        class="list_body_time"
                                    >
                                        1 000 000/day
                                    </div>
                                    <div
                                        v-else
                                        class="list_body_time"
                                        style="margin-top: 6px"
                                    >
                                        {{ stakingFormattedTimer }}
                                    </div>
                                </div>
                            </div>
                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/UI/loading-small.vue"
import Wallet from "@/components/Wallet.vue"
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue"
// import required modules
import { Pagination, Navigation } from "swiper/modules"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

import imgUrl1 from "@/assets/img/left-click.svg"
import imgUrl2 from "@/assets/img/speedometer.svg"
import imgUrl3 from "@/assets/img/robot.svg"
import imgUrl4 from "@/assets/img/MINING.svg"
import imgUrl5 from "@/assets/img/safe.svg"
import { mapActions, mapMutations, mapState } from "vuex"
import { boostService } from "@/services/boost.service"
import moment from "moment"
import { clickService } from "@/services/click.service"
import NomoneyModal from "@/components/nomoney.modal.vue"
import SuccessfulModal from "@/components/Successful.modal.vue"
import FailedModal from "@/components/Failed.modal.vue"
import TurboactiveModal from "@/components/Turboactive.modal.vue"
export default {
    components: {
        Swiper,
        SwiperSlide,
        Loading,
        Wallet,
        NomoneyModal,
        SuccessfulModal,
        FailedModal,
        TurboactiveModal,
    },
    setup() {
        return {
            modules: [Pagination, Navigation],
        }
    },
    data() {
        return {
            imgUrl1,
            imgUrl2,
            imgUrl3,
            imgUrl4,
            imgUrl5,
            loading: false,
            turboList: [
                {
                    card: "#bdffe7",
                    background: "#40515a",
                },
                {
                    card: "#fcff50",
                    background: `linear-gradient(
                                    90deg,
                                    #5a99bc 0%,
                                    #dadd26 100%
                                )`,
                },
                {
                    card: "#ffaf50",
                    background: `linear-gradient(
                                    90deg,
                                    #5a99bc 0%,
                                    #e78a1c 100%
                                )`,
                },
                {
                    card: "#41fdba",
                    background: `linear-gradient(
                                    90deg,
                                    #5a99bc 0%,
                                    #bc2e2e 100%
                                )`,
                },
            ],
            resetList: [
                {
                    card: "#41fdba",
                    background: `linear-gradient(
                                    90deg,
                                    #294656 0%,
                                    #31bc2e 100%
                                )`,
                },
                {
                    card: "#bdffe8",
                    background: "#40515a",
                },
            ],
            resetFormattedTimer: moment(0).format("HH:mm:ss"),
            stakingFormattedTimer: moment(0).format("HH:mm:ss"),
            showNoMoney: false,
            showSuccess: false,
            showFailed: false,
            showTurbo: false,
        }
    },
    computed: {
        ...mapState("user", [
            "data",
            "resetTimer",
            "turboTime",
            "clicks",
            "turboClicks",
            "stakingTimer",
        ]),
    },
    watch: {
        resetTimer(newVal) {
            this.resetFormattedTimer = moment.utc(newVal).format("HH:mm:ss")
        },
        stakingTimer(newVal) {
            this.stakingFormattedTimer = moment.utc(newVal).format("HH:mm:ss")
        },
    },
    mounted() {
        this.resetFormattedTimer = moment
            .utc(this.resetTimer)
            .format("HH:mm:ss")
        this.stakingFormattedTimer = moment
            .utc(this.stakingTimer)
            .format("HH:mm:ss")
    },
    methods: {
        ...mapMutations("user", ["setUser", "clearClicks"]),
        ...mapActions("user", ["activateTurboTime"]),
        async turbo() {
            if (this.data.turboResidue !== 0 && !this.turboTime) {
                try {
                    this.activateTurboTime()
                    this.setUser(await boostService.turbo())
                    this.showTurbo = true
                    setTimeout(() => {
                        this.showTurbo = false
                    }, 3000)
                } catch (error) {
                    this.showFailed = true
                    setTimeout(() => {
                        this.showFailed = false
                    }, 3000)
                }
            }
        },
        async reset() {
            if (!this.data.usedReset) {
                this.setUser(await boostService.reset())
            }
        },
        async loadir(id) {
            document
                .querySelectorAll(".level_up .list_item")
                .forEach((e, i) => {
                    if (i == id - 1) {
                        e.classList.add("loading")
                        setTimeout(() => {
                            e.classList.remove("loading")
                        }, 1000)
                    }
                })
            switch (id) {
                case 1:
                    if (
                        this.data.balance >=
                        Math.round(3000 * 1.4 ** this.data.multitapLevel)
                    ) {
                        try {
                            this.setUser(await boostService.upgrade("multitap"))
                            this.setUser(
                                await clickService.default(this.clicks)
                            )
                            this.clearClicks()
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                            }, 5000)
                        } catch (error) {
                            this.showFailed = true
                            setTimeout(() => {
                                this.showFailed = false
                            }, 3000)
                        }
                    } else {
                        this.showNoMoney = true
                        setTimeout(() => {
                            this.showNoMoney = false
                        }, 3000)
                    }
                    break
                case 2:
                    if (
                        this.data.balance >=
                        Math.round(50000 * 2.1 ** this.data.speedLimitLevel)
                    ) {
                        try {
                            this.setUser(
                                await boostService.upgrade("speedLimit")
                            )
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                            }, 5000)
                        } catch (error) {
                            this.showFailed = true
                            setTimeout(() => {
                                this.showFailed = false
                            }, 3000)
                        }
                    } else {
                        this.showNoMoney = true
                        setTimeout(() => {
                            this.showNoMoney = false
                        }, 3000)
                    }
                    break
                case 3:
                    if (
                        this.data.balance >=
                        Math.round(10000 * 2.1 ** this.data.autobotLevel)
                    ) {
                        try {
                            this.setUser(await boostService.upgrade("autobot"))
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                            }, 5000)
                        } catch (error) {
                            this.showFailed = true
                            setTimeout(() => {
                                this.showFailed = false
                            }, 3000)
                        }
                    } else {
                        this.showNoMoney = true
                        setTimeout(() => {
                            this.showNoMoney = false
                        }, 3000)
                    }
                    break
                case 4:
                    if (
                        this.data.balance >=
                        Math.round(50000 * 2.8 ** this.data.miningLevel)
                    ) {
                        try {
                            this.setUser(await boostService.upgrade("mining"))
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                            }, 5000)
                        } catch (error) {
                            this.showFailed = true
                            setTimeout(() => {
                                this.showFailed = false
                            }, 3000)
                        }
                    } else {
                        this.showNoMoney = true
                        setTimeout(() => {
                            this.showNoMoney = false
                        }, 3000)
                    }
                    break
                case 5:
                    if (this.data.balance >= 500000) {
                        try {
                            this.setUser(await boostService.upgrade("staking"))
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                            }, 5000)
                        } catch (error) {
                            this.showFailed = true
                            setTimeout(() => {
                                this.showFailed = false
                            }, 3000)
                        }
                    } else {
                        this.showNoMoney = true
                        setTimeout(() => {
                            this.showNoMoney = false
                        }, 3000)
                    }
                    break
            }
        },
        goToSlide() {
            const slideIndex = this.data.turboResidue
            if (slideIndex >= 0 && slideIndex < 3) {
                this.$refs.mySwiper.swiper.slideTo(slideIndex)
            }
        },
    },
}
</script>

<style lang="scss">
.boost {
    background: linear-gradient(
        180deg,
        #151d30 0%,
        #151d30 44.59%,
        #151d30 100%
    );
    .boost_in {
        min-height: 100vh;
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        padding: 20px 0 150px;
        background-image: url("@/assets/img/boost_bg.png");
        background-position: bottom center;
        background-size: cover;
        background-repeat: no-repeat;
        .back {
            top: 0;
            left: 22px;
        }
        .logo {
            margin-bottom: 17px;
        }
        .balance {
            height: 42px;
            margin: 0 auto;
            border-radius: 13px;
            padding: 0 24px 0 12px;
            margin-bottom: 6px;
            background: linear-gradient(to right, #52ff53 0%, #41fdb9 68.5%);
            background-image: linear-gradient(
                to right,
                #52ff53,
                #41fdb9,
                #52ff53,
                #41fdb9
            );
            background-size: 200%;
            animation: balanceBack 5s infinite;
            .head {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 18px;
                color: #000;
            }
            .balance_val {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 18px;
                color: #000;
            }
        }

        .daily_boosters {
            margin-bottom: 33px;
            .title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 18px;
                color: #dbdbdb;
                line-height: 24px;
                margin-bottom: 11px;
            }
            .daily_boosters_card {
                grid-template-columns: repeat(2, 1fr);
                gap: 23px;
                .card_item {
                    max-width: 145px;
                    width: 100%;
                    min-width: 100%;
                    .daily_boosters_swiper {
                        border-radius: 8px;
                        height: 100%;

                        .daily_boosters_swiper_block {
                            padding: 10px 15px 10px 13px;
                            background: #40515a;
                            border-radius: 8px;

                            .card_img {
                                border-radius: 8px;
                                width: 38px;
                                height: 38px;
                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                            .card_title {
                                font-family: var(--font-family);
                                font-weight: 500;
                                font-size: 14px;
                                color: #dbdbdb;

                                &__block {
                                    display: flex;
                                    flex-direction: column;

                                    & div:nth-child(2) {
                                        width: fit-content;
                                        left: 13px;
                                        font-family: var(--font-family);
                                        font-weight: 500;
                                        font-size: 13px;
                                        color: #dbdbdb;
                                    }
                                }
                            }
                            .card_hour {
                                font-family: var(--font-family);
                                font-weight: 500;
                                font-size: 13px;
                                color: #dbdbdb;
                                line-height: 13px;
                            }
                        }

                        .rest_boots_slide {
                            height: 100%;
                        }
                        .swiper-pagination-fraction {
                            width: fit-content;
                            left: 13px;
                            font-family: var(--font-family);
                            font-weight: 500;
                            font-size: 13px;
                            color: #dbdbdb;
                        }
                    }
                }
            }
        }
        .level_up {
            .title {
                font-family: var(--font-family);
                font-weight: 700;
                font-size: 18px;
                color: #dbdbdb;
                margin-bottom: 10px;
            }
            .level_up_list {
                gap: 10px;
                .list_item {
                    border-radius: 11px;
                    backdrop-filter: blur(10px);
                    height: 65px;
                    padding: 12px;
                    gap: 20px;
                    .list_item_in {
                        gap: 20px;
                    }
                    .list_img {
                        border-radius: 11px;
                        width: 45px;
                        height: 45px;
                        background: linear-gradient(
                            180deg,
                            #41fdba 0%,
                            #52ff54 100%
                        );
                    }
                    .list_body {
                        .list_body_title {
                            font-family: var(--font-family);
                            font-weight: 500;
                            font-size: 14px;
                            color: #dbdbdb;
                            line-height: 14px;
                            span {
                                color: rgba(255, 255, 255, 0.5);
                            }
                        }
                        .list_body_sum {
                            font-family: var(--font-family);
                            font-weight: 500;
                            font-size: 14px;
                            color: #dbdbdb;
                            line-height: 17px;
                        }
                        .list_body_time {
                            font-family: var(--font-family);
                            font-weight: 500;
                            font-size: 14px;
                            color: #dbdbdb;
                            line-height: 14px;
                        }
                    }
                    &:nth-child(1) {
                        background: linear-gradient(
                            90deg,
                            rgba(67, 254, 176, 0.5) 0%,
                            rgba(41, 70, 86, 0.5) 100%
                        );
                    }
                    &:nth-child(2) {
                        background: linear-gradient(
                            90deg,
                            rgba(49, 148, 104, 0.5) 0%,
                            rgba(23, 60, 78, 0.5) 100%
                        );
                        .list_img {
                            background: linear-gradient(
                                90deg,
                                #41fdba 0%,
                                #ffce2e 100%
                            );
                        }
                    }
                    &:nth-child(3) {
                        background: linear-gradient(
                            90deg,
                            rgba(254, 219, 65, 0.5) 0%,
                            rgba(41, 70, 86, 0.5) 100%
                        );
                        .list_img {
                            background: linear-gradient(
                                180deg,
                                #52ff55 0%,
                                #fcf429 100%
                            );
                        }
                    }
                    &:nth-child(4) {
                        background: linear-gradient(
                            90deg,
                            rgba(184, 143, 255, 0.5) 0%,
                            rgba(41, 70, 86, 0.5) 100%
                        );
                        .list_img {
                            background: linear-gradient(
                                180deg,
                                #d2c0ff 0%,
                                #f9ea67 100%
                            );
                        }
                    }
                    &:nth-child(5) {
                        background: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0.5) 0%,
                            rgba(41, 70, 86, 0.5) 100%
                        );
                        .list_img {
                            background: linear-gradient(
                                180deg,
                                #e7ffe8 0%,
                                #8e8e8e 100%
                            );
                        }
                    }
                }
                .loading {
                    .loader-small {
                        display: block;
                    }
                }
            }
        }
        @keyframes balanceBack {
            0% {
                background-position-x: left;
            }
            50% {
                background-position-x: center;
            }
            100% {
                background-position-x: left;
            }
        }
    }
}
</style>
