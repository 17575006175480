import $api from "@/api/interceptors"
import { Languages } from "@/types/user.type"
import axios from "axios"

class UserService {
    async data() {
        return (await $api.get("/user")).data.data
    }

    async language(language: Languages) {
        return (
            await $api.post("/user/language", {
                language,
            })
        ).data.data
    }

    async isFirst(id: number) {
        return (
            await axios.post("https://stakeapp.site/api/user/is-first", {
                id,
            })
        ).data.data
    }
}

export const userService = new UserService()
